import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [selectedTag, setSelectedTag] = useState("All");
  const [tags, setTags] = useState(["All"]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/blogs`);
        setBlogs(response.data);
        setFilteredBlogs(response.data);

        // Extract unique tags from blog data and include "All" as default
        const allTags = response.data.reduce(
          (acc, blog) => {
            blog.tags?.forEach((tag) => {
              if (!acc.includes(tag)) {
                acc.push(tag);
              }
            });
            return acc;
          },
          ["All"]
        );
        setTags(allTags);
      } catch (error) {
        console.error("Error fetching blogs", error);
      }
    };

    fetchBlogs();
  }, []);

  // Handle tag filtering
  const handleTagFilter = (tag) => {
    setSelectedTag(tag);
    if (tag === "All") {
      setFilteredBlogs(blogs);
    } else {
      const filtered = blogs.filter((blog) => blog.tags?.includes(tag));
      setFilteredBlogs(filtered);
    }
  };

  // Handle blog click and redirect to the blog details page
  const handleBlogClick = (blogId) => {
    navigate(`/blogs/${blogId}`);
  };

  return (
    <BlogSection>
      <Title>Blogs</Title>
      <Divider />

      {/* Tag Filter Buttons */}
      <TagFilterContainer>
        {tags.map((tag, index) => (
          <TagButton
            key={index}
            onClick={() => handleTagFilter(tag)}
            isActive={selectedTag === tag}
          >
            {tag}
          </TagButton>
        ))}
      </TagFilterContainer>

      <GridContainer>
        {filteredBlogs.map((blog) => (
          <BlogCard
            key={blog._id}
            onClick={() => handleBlogClick(blog._id)}
          >
            {/* Clickable Image */}
            <ImageContainer>
              <BlogImage
                src={blog.image}
                alt={blog.title}
              />
            </ImageContainer>
            {/* Blog Title */}
            <BlogTitle>{blog.title}</BlogTitle>
            {/* Blog Tags */}
            <BlogTags>{blog.tags?.join(", ")}</BlogTags>
          </BlogCard>
        ))}
      </GridContainer>
    </BlogSection>
  );
};

export default Blogs;

// Styled-components with theme integration
const BlogSection = styled.section`
  padding: 0 40px 40px;
  text-align: center;
  color: ${(props) => props.theme.text};
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin: 0px;
  text-align: left !important;
  color: ${(props) => props.theme.text};
`;

const Divider = styled.hr`
  width: 50px;
  border: 3px solid ${(props) => props.theme.highlight};
  border-radius: 5px;
  margin: 2em 0;
  margin-left: 0;
`;

const TagFilterContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  flex-wrap: wrap;
`;

const FilterButton = ({ isActive, ...props }) => <button {...props} />;

const TagButton = styled(FilterButton)`
  background-color: ${({ theme }) =>
    theme.container};
  color: ${({ theme }) => theme.text};
  border: 1px solid ${({ theme }) => theme.highlight};
  border-radius: 5px;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ theme }) =>
      "rgba(232, 203, 147, 0.2)"};
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 20px;
  line-height: 1em;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const BlogCard = styled.div`
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 10px;
`;

const BlogImage = styled.img`
  width: 100%;
  height: 100%;
  margin: 5px 0 0;
  object-fit: cover;
  transition: transform 0.2s ease-in-out;
  text-align: left !important;

  &:hover {
    transform: scale(1.05);
  }
`;

const BlogTitle = styled.p`
  text-align: left !important;
  margin: 15px 0 0;
  font-size: 0.9em;
  opacity: 0.7;
`;

const BlogTags = styled.p`
  text-align: left !important;
  margin: 5px 0 0;
  font-size: 0.8em;
  opacity: 0.5;
`;