export const lightTheme = {
  // main: "#ffffff",
  // secondary: "#f2f2f2",
  // container: "#e6e6e6",
  // subContainer: "#"
  // text: "#333333",
  // highlight: "#d1a766",

  // main: "#ffffff", // White background for the main area
  // secondary: "#f2f2f2", // Light gray for secondary background
  // container: "#e6e6e6", // Light gray for containers
  // subContainer: "#dcdcdc", // Lighter shade of gray for sub-containers
  // text: "#333333", // Dark gray text color for readability
  // highlight: "#d1a766", // A warm golden color for highlights

  main: "#fafafa", // Soft off-white background for a gentle feel
  secondary: "#f4f4f4", // Light gray for secondary areas, providing contrast without being too stark
  container: "#e0e0e0", // Subtle gray for containers, giving enough distinction
  subContainer: "#d1d1d1", // Light gray for sub-containers
  text: "#333333", // Dark gray for text, ensuring readability
  highlight: "#b58c3b", // A warm amber for highlights, more natural and softer than yellow
};

export const darkTheme = {
  main: "#111111",
  secondary: "#1e1e1e",
  container: "#323232",
  subContainer: "#252626",
  text: "#d3d3d3",
  highlight: "#e3cc9a",
};
