import React from "react";
import styled from "styled-components";
import { MdOutlineQueryStats, MdWeb, MdManageHistory } from "react-icons/md";
import { TbCloudComputing } from "react-icons/tb";

const Summary = () => {
  return (
    <SummarySection>
      {/* First Section: Title */}
      <Title>About Me</Title>
      <Divider />

      <SummaryText>
        Hi, I’m Manavkumar Patel, a graduate student in Computer Science at the
        Illinois Institute of Technology, focusing on Artificial Intelligence
        and Data analysis. I am always seeking the most efficient solutions to
        challenges🔍
      </SummaryText>
      <SummaryText>
        With expertise in Management, Full-Stack Development, Artificial
        Intelligence, and Data Analytics, I specialize in transforming complex
        data into actionable and impactful solutions. My journey in these fields
        began early, as I balanced academics by day and honed my coding skills
        by night.
      </SummaryText>
      <SummaryText>
        Over the past three years, I've led projects from concept to completion,
        consistently delivering tangible results. At Interstellar Scientific
        Pvt. Ltd., I spearheaded scalable application development, and at Pranam
        Enterprise, I enhanced inventory management by 30% through predictive
        analytics. While at Onelab Ventures, I managed the successful delivery
        of multiple end-to-end B2B solutions that significantly improved system
        performance and user experience.
      </SummaryText>
      <SummaryText>
        On any given day, I’m writing code, analyzing data for actionable
        insights, collaborating with teams, diving into the latest in AI, and
        brainstorming creative solutions. I’m also committed to continuous
        growth, which is why I’m currently pursuing a Master’s in Computer
        Science with a focus on AI and Data Analytics.
      </SummaryText>
      <SummaryText>
        Let’s connect! Whether you’re interested in discussing project
        management, AI, data-driven innovation, or just grabbing a coffee ☕,
        feel free to reach out!
      </SummaryText>

      {/* Third Section: Technologies Grid */}
      <SectionTitle>What I'm Doing</SectionTitle>
      <Divider />
      <GridContainer>
        <TechnologyCard>
          <IconAndDetailsContainer>
            <FaviconContainer>
              <MdOutlineQueryStats
                style={{
                  color: "#e8cb93",
                  height: "1.8em",
                  width: "1.8em",
                }}
              />
            </FaviconContainer>
            <DetailsContainer>
              <TechName>Data Science</TechName>
              <TechDescription>
                I am proficient in data analysis, machine learning, and building
                predictive models.
              </TechDescription>
            </DetailsContainer>
          </IconAndDetailsContainer>
        </TechnologyCard>

        <TechnologyCard>
          <IconAndDetailsContainer>
            <FaviconContainer>
              <MdWeb
                style={{
                  color: "#e8cb93",
                  height: "1.8em",
                  width: "1.8em",
                }}
              />
            </FaviconContainer>
            <DetailsContainer>
              <TechName>Full Stack Development</TechName>
              <TechDescription>
                Skilled in front-end and back-end development across multiple
                tech stacks.
              </TechDescription>
            </DetailsContainer>
          </IconAndDetailsContainer>
        </TechnologyCard>

        <TechnologyCard>
          <IconAndDetailsContainer>
            <FaviconContainer>
              <TbCloudComputing
                style={{
                  color: "#e8cb93",
                  height: "1.8em",
                  width: "1.8em",
                }}
              />
            </FaviconContainer>
            <DetailsContainer>
              <TechName>Cloud (AWS)</TechName>
              <TechDescription>
                Experience in deploying and managing scalable applications on
                AWS.
              </TechDescription>
            </DetailsContainer>
          </IconAndDetailsContainer>
        </TechnologyCard>

        <TechnologyCard>
          <IconAndDetailsContainer>
            <FaviconContainer>
              <MdManageHistory
                style={{
                  color: "#e8cb93",
                  height: "1.8em",
                  width: "1.8em",
                }}
              />
            </FaviconContainer>
            <DetailsContainer>
              <TechName>Product Management</TechName>
              <TechDescription>
                Proficient in leading cross-functional teams to build innovative
                products.
              </TechDescription>
            </DetailsContainer>
          </IconAndDetailsContainer>
        </TechnologyCard>
      </GridContainer>
    </SummarySection>
  );
};

export default Summary;

// Styled-components at the end of the file

const SummarySection = styled.section`
  padding: 0 40px 40px;
  line-height: 1.6;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin: 0px;
`;

const Divider = styled.hr`
  width: 50px;
  border: 3px solid;
  border-radius: 5px; /* Rounds the edges */
  margin: 2em 0;
  color: ${(props) => props.theme.highlight};
  margin-left: 0; /* Aligns the hr to the left */
`;

const SummaryText = styled.p`
  font-size: 1.2rem;
  margin-bottom: 40px;
`;

const SectionTitle = styled.h2`
  font-size: 2rem;
  margin: 80px 0 0;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const TechnologyCard = styled.div`
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
`;

const IconAndDetailsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const FaviconContainer = styled.div`
  width: 1.8em;
  height: 1.8em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

const Favicon = styled.img`
  width: 100%;
  height: 100%;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const TechName = styled.h3`
  font-size: 1.2rem;
  margin: 0;
`;

const TechDescription = styled.p`
  font-size: 0.9rem;
  margin: 0;
  margin-top: 5px;
`;
