import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom"; // Use Link for client-side navigation
import { useTheme } from "../context/ThemeContext"; // Import useTheme hook
import { FaSun, FaMoon } from "react-icons/fa"; // Import icons for sun and moon

const Navbar = () => {
  const { toggleTheme, isDarkMode, theme } = useTheme(); // Get toggleTheme function, current theme status, and theme object

  return (
    <Nav theme={theme}>
      {/* Theme Toggle Switch */}
      <Label className="switch">
        <Input type="checkbox" checked={isDarkMode} onChange={toggleTheme} />
        <Slider className="slider round" />
      </Label>

      <NavItem theme={theme} to="/about">
        About
      </NavItem>
      <NavItem theme={theme} to="/project">
        Projects
      </NavItem>
      <NavItem theme={theme} to="/experience">
        Experience
      </NavItem>
      <NavItem theme={theme} to="/education">
        Education
      </NavItem>
      <NavItem theme={theme} to="/skills">
        Skills
      </NavItem>
      <NavItem theme={theme} to="/contact">
        Contact
      </NavItem>
      <NavItem theme={theme} to="/blogs">
        Blogs
      </NavItem>
    </Nav>
  );
};

// Styled components with theme support for the Navbar
const Nav = styled.nav`
  display: flex;
  gap: 40px;
  overflow-x: scroll; /* Enable horizontal scrolling */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */

  /* Hide scrollbar for Chrome, Safari, and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  color: ${(props) => props.theme.text}; /* Dynamic text color */
`;

// Styled component for individual Nav items
const NavItem = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.text}; /* Dynamic text color */
  font-weight: bold;

  &:hover {
    color: ${(props) => props.theme.highlight}; /* Dynamic highlight color */
  }

  &.button {
    padding: 8px 16px; /* Add some padding for the button */
    background-color: transparent !important;
    border: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.theme.text} !important;
    font-weight: bold;

    &:hover {
      background-color: ${(props) =>
        props.theme.subContainer}; /* Dynamic background color on hover */
      color: ${(props) =>
        props.theme.highlight}; /* Dynamic highlight color on hover */
    }
  }
`;

// Styled component for the Toggle Switch
const Label = styled.label`
  position: relative;
  display: inline-block;
  width: 40px; /* Adjusted width */
  height: 22px; /* Adjusted height */
`;

const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 22px; /* Rounded corners for smaller switch */

  &::before {
    position: absolute;
    content: "";
    height: 18px; /* Adjusted size of the knob */
    width: 18px; /* Adjusted size of the knob */
    left: 2px; /* Adjusted to center the knob */
    bottom: 2px; /* Adjusted to center the knob */
    background-color: white;
    transition: 0.4s;
    border-radius: 50%; /* Keep it round */
  }

  /* When the checkbox is checked */
  input:checked + & {
  }

  input:checked + &::before {
    transform: translateX(18px); /* Adjusted for smaller switch size */
  }
`;

export default Navbar;
