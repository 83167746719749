import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import { useTheme } from "../context/ThemeContext";

const DetailedBlogs = () => {
  const { blog_id } = useParams();
  const { theme } = useTheme();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlogDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/blogs/${blog_id}`
        );
        setBlog(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching blog details", error);
        setLoading(false);
      }
    };

    fetchBlogDetails();
  }, [blog_id]);

  if (loading) {
    return <Loading theme={theme}>Loading...</Loading>;
  }

  if (!blog) {
    return <ErrorMessage>Blog post not found.</ErrorMessage>;
  }

  return (
    <BlogSection theme={theme}>
      <Divider theme={theme} />
      <Title theme={theme}>{blog.title}</Title>
      <BlogContainer theme={theme}>
        <BlogImageContainer theme={theme}>
          <BlogImage src={blog.image} alt={blog.title} />
        </BlogImageContainer>
        <BlogInfo theme={theme}>
          <BlogMeta theme={theme}>
            <DetailItem theme={theme}>
              <strong>Published:</strong>{" "}
              {new Date(blog.date).toLocaleDateString()}
            </DetailItem>
            <DetailItem theme={theme}>
              <strong>Tags:</strong> {blog.tags?.join(", ")}
            </DetailItem>
          </BlogMeta>
          <Content
            theme={theme}
            dangerouslySetInnerHTML={{ __html: blog.content }}
          />
        </BlogInfo>
      </BlogContainer>
    </BlogSection>
  );
};

export default DetailedBlogs;

// Styled-components
const BlogSection = styled.section`
  padding: 0 40px 40px;
  text-align: center;
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text};
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin: 0px;
  text-align: left !important;
  color: ${({ theme }) => theme.primary};
`;

const Divider = styled.hr`
  width: 50px;
  border: 3px solid ${({ theme }) => theme.highlight};
  border-radius: 5px;
  margin: 2em 0;
  margin-left: 0;
`;

const BlogContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  background-color: ${({ theme }) => theme.cardBackground};
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const BlogImageContainer = styled.div`
  width: 100%;
  height: auto;
  border-radius: 10px;
`;

const BlogImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`;

const BlogInfo = styled.div`
  flex: 1;
  padding: 20px;
`;

const BlogMeta = styled.div`
  margin-bottom: 20px;
`;

const Content = styled.div`
  text-align: left;
  font-size: 1.1rem;
  line-height: 1.8;
  color: ${({ theme }) => theme.text};
  margin-top: 20px;
`;

const DetailItem = styled.p`
  text-align: left;
  margin-bottom: 10px;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.text};
`;

const Loading = styled.div`
  font-size: 2rem;
  text-align: center;
  margin-top: 50px;
  color: ${({ theme }) => theme.text};
`;

const ErrorMessage = styled.p`
  font-size: 1.5rem;
  text-align: center;
  color: red;
  margin-top: 50px;
`;