import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import styled, {
  ThemeProvider as StyledThemeProvider,
} from "styled-components"; // Import ThemeProvider from styled-components
import Navbar from "./components/Navbar";
import About from "./components/About";
import Summary from "./components/summary";
import Experience from "./components/experience";
import Education from "./components/education";
import Portfolio from "./components/Portfolio";
import DetailProject from "./components/detailProject";
import Skills from "./components/skills";
import Contact from "./components/Contact";
import Blogs from "./components/Blogs"; // Update import statement to match the correct case
import DetailedBlogs from "./components/DetailedBlogs";
import { ThemeProvider, useTheme } from "./context/ThemeContext"; // Import your ThemeContext

const App = () => {
  const { theme } = useTheme(); // Get the current theme from your context

  useEffect(() => {
    // Apply the theme to the body element
    document.body.style.backgroundColor = theme.main;
    document.body.style.color = theme.text;
  }, [theme]);

  return (
    // Wrap the entire app with the styled-components ThemeProvider
    <StyledThemeProvider theme={theme}>
      <Router>
        <MainBackground>
          <PrimaryContainer>
            <AboutContainer>
              <About />
            </AboutContainer>
            <InfoContainer>
              <NavbarContainer>
                <NavbarContainerWrapper>
                  <Navbar />
                </NavbarContainerWrapper>
              </NavbarContainer>
              <Routes>
                <Route path="/" element={<Summary />} />
                <Route path="/about" element={<Summary />} />
                <Route path="/project" element={<Portfolio />} />
                <Route
                  path="/project/:project_id"
                  element={<DetailProject />}
                />
                <Route path="/experience" element={<Experience />} />
                <Route path="/education" element={<Education />} />
                <Route path="/skills" element={<Skills />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/blogs" element={<Blogs />} />
                <Route path="/blogs/:blog_id" element={<DetailedBlogs />} />
              </Routes>
            </InfoContainer>
          </PrimaryContainer>
        </MainBackground>
      </Router>
    </StyledThemeProvider>
  );
};

// Styled components remain the same, but they will now automatically have access to the theme
const MainBackground = styled.div`
  background-color: ${(props) => props.theme.main};
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PrimaryContainer = styled.div`
  display: flex;
  width: 88vw;
  margin: 6%;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
  }
`;

const AboutContainer = styled.div`
  width: 25%;
  background-color: ${(props) => props.theme.secondary};
  padding: 20px;
  color: ${(props) => props.theme.text};
  border-radius: 18px;

  h2,
  p {
    color: inherit;
  }

  h1:hover {
    color: ${(props) => props.theme.highlight};
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 0px;
    margin-bottom: 20px;
    border-radius: 0 18px 0 18px;
  }
`;

const InfoContainer = styled.div`
  width: 75%;
  margin: 0 0 0 2%;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.secondary};
  color: ${(props) => props.theme.text};
  border-radius: 18px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
    margin: 0;
  }
`;

const NavbarNavbarContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  border-radius: 18px 18px 0 0;
  padding: 20px;
  background-color: ${(props) => props.theme.container};

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const NavbarContainerWrapper = styled.div`
  padding-left: 5%;
  padding: 20px 20px 20px 20px;
  border-radius: 0 18px 0 18px;
  background-color: ${(props) => props.theme.subContainer};

  @media (max-width: 768px) {
    justify-content: center; /* Center navbar on mobile */
  }
`;

const NavbarContainer = styled.div`
  display: flex;
  justify-content: flex-end; /* Aligns the navbar to the right */
  border-radius: 18px 18px 0 0;

  @media (max-width: 768px) {
    justify-content: center; /* Center navbar on mobile */
    display: inline;
  }
`;

const SummaryContainer = styled.div`
  padding: 20px;
  background-color: ${(props) =>
    props.theme.secondary}; /* Use dynamic background color */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 18px 18px;
  height: 100%;
  color: ${(props) => props.theme.text}; /* Use dynamic text color */

  h1,
  p {
    color: inherit;
  }

  h1:hover {
    color: ${(props) =>
      props.theme.highlight}; /* Hover effect with theme highlight color */
  }

  @media (max-width: 768px) {
    padding: 15px;
    height: auto;
  }
`;

export default App;
